import React, {useEffect, useContext} from 'react';
import AppContext from '../context/app/appContext';
import Loading from './Loading';
import Module from './Module';

const RidesTotalMinutes = () => {
    let total = [];
    const appContext = useContext(AppContext);
    const { getTotalMinutesOfRides, totalMinutesOfRides, token, loading } = appContext;

    useEffect(() => {
        const timer = setTimeout(() => {
            getTotalMinutesOfRides(token);
        }, 1000);

        const interval = setInterval(() => {
            const timer = setTimeout(() => {
                getTotalMinutesOfRides(token);
                console.log('getTotalMinutesOfRides every 5min'); 
            }, 500);
            return () => clearTimeout(timer);
        }, 300 * 1000);
          return () => {
            clearTimeout(timer);
            clearInterval(interval);
          }
    }, [token])

    if(loading) {
        return (
            <Loading/>
        )
    }

    if(!totalMinutesOfRides || totalMinutesOfRides.length === 0) {
        return null;
    }

    totalMinutesOfRides.data.map((item) => {
        total.push(item.value);
    })

    return (
       <Module value={total.reduce((a, b) => a + b)} />
    )
}

export default RidesTotalMinutes;
