import React, {useEffect, useContext} from 'react';
import AppContext from '../context/app/appContext';
import Loading from './Loading';
import Text from './Text';

const RidesTraveled = () => {
    let values = [];
    let totalRides = 0;
    const appContext = useContext(AppContext);
    const { getRidesTraveled, ridesTraveled, token, loading } = appContext;

    useEffect(() => {
        const timer = setTimeout(() => {
            getRidesTraveled(token);
        }, 1000);

        const interval = setInterval(() => {
            const timer = setTimeout(() => {
                getRidesTraveled(token);
                console.log('getRidesTraveled every 5min'); 
            }, 500);
            return () => clearTimeout(timer);
        }, 300 * 1000);
          return () => {
            clearTimeout(timer);
            clearInterval(interval);
          }
    }, [token])

    if(loading) {
        return (
            <Loading/>
        )
    }

    if(!ridesTraveled || ridesTraveled.length === 0) {
        return null;
    }
    
    ridesTraveled.data.map((item, i) => {
        values.push(item.value);
    });

    totalRides = values.reduce((a, b) => a + b);
    let lessArr = [];
    let moreArr = [];
    ridesTraveled.data.map((obj, value) => {
        if(obj.key < 5){
            lessArr.push(obj.value);
        } else if( obj.key >= 5 ) {
            moreArr.push(obj.value);
        }
    });

    const lessAmount = lessArr.reduce((a, b) => a + b);
    const moreAmount = moreArr.reduce((a, b) => a + b);

    const lessPrecent = lessAmount * 100 / totalRides;
    const morePrecent = moreAmount * 100 / totalRides;

    //console.log(values, 'values');
    // console.log(totalRides, 'totalRides');
    // console.log(lessAmount, 'lessAmount');
    // console.log(moreAmount, 'moreAmount');
    const result = `${Math.floor(lessPrecent)}/${Math.ceil(morePrecent)}`;

    return (
        <Text value={`${result} %`} />
    )
}

export default RidesTraveled;
