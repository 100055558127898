import React, { useContext, useEffect } from "react";
import AppContext from "./context/app/appContext";
import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import RidesToday from "./components/ridesToday";
import RidesTotal from "./components/ridesTotal";
import RidesDistance from "./components/ridesDistance";
import BestDayOfTheWeek from "./components/ridesBestDayOfWeek";
import BestTraveled from "./components/ridesTraveled";
import RidesTotalMinutes from "./components/ridesTotalMinutes";

export default () => {
  const appContext = useContext(AppContext);
  const { authenticating } = appContext;

  useEffect(() => {
    authenticating();

    const interval = setInterval(() => {
      authenticating();
      console.log("Authenticating every 1 hour");
    }, 3600 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="dashboard">
      <section className="dashboard--inner">
        <Header />

        <section className="stats my-3 my-lg-5">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-6 col-xl-4 module mb-4">
              <div className="module--wrapper">
                <div className="module--inner d-flex flex-column align-items-center justify-content-center p-5">
                  <div className="module--caption text-uppercase align-self-xl-start flex-grow-1">
                    Total antal cykelture
                  </div>

                  <RidesTotal />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-4 module mb-4">
              <div className="module--wrapper">
                <div className="module--inner d-flex flex-column align-items-center justify-content-center p-5">
                  <div className="module--caption text-uppercase align-self-xl-start flex-grow-1">
                    Gennemsnit antal km/ugen
                  </div>

                  <RidesDistance />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-4 module mb-4">
              <div className="module--wrapper">
                <div className="module--inner d-flex flex-column align-items-center justify-content-center p-5">
                  <div className="module--caption text-uppercase align-self-xl-start flex-grow-1">
                    Total antal cyklede minutter
                  </div>

                  <RidesTotalMinutes />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-4 module mb-4">
              <div className="module--wrapper">
                <div className="module--inner d-flex flex-column align-items-center justify-content-center p-5">
                  <div className="module--caption text-uppercase align-self-xl-start flex-grow-1">
                    Antal cykelture i dag
                  </div>

                  <RidesToday />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-4 module mb-4">
              <div className="module--wrapper">
                <div className="module--inner d-flex flex-column align-items-center justify-content-center p-5">
                  <div className="module--caption text-uppercase align-self-xl-start flex-grow-1">
                    Procent cykelture under/over 5 km
                  </div>

                  <BestTraveled />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-4 module mb-4">
              <div className="module--wrapper">
                <div className="module--inner d-flex flex-column align-items-center justify-content-center p-5">
                  <div className="module--caption text-uppercase align-self-xl-start flex-grow-1">
                    Ugedag med mest cykling
                  </div>

                  <BestDayOfTheWeek />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </section>
    </div>
  );
};
