import React from 'react';

const Module = ({value}) => {
  const formattedValue = new Intl.NumberFormat('da-DK', {
    style: 'decimal',
    maximumFractionDigits: 0,
  }).format(value);
  
  return(
    <div className="module--data text-color-main flex-grow-1 text-uppercase">
      {formattedValue}
    </div>
  )
}
export default Module;