import React from 'react'

const Loading = () => {
  return(
    <div className="module--loading text-color-main flex-grow-1">
      <div className="spinner">
        <span className="spinner-inner-1"></span>
        <span className="spinner-inner-2"></span>
        <span className="spinner-inner-3"></span>
      </div>
    </div>
  )
}
export default Loading;