import React, { useEffect, useContext } from "react";
import AppContext from "../context/app/appContext";
import Loading from "./Loading";
import Module from "./Module";

const RidesDistance = () => {
  let values = [];
  const appContext = useContext(AppContext);
  const { getRidesDistance, ridesDistance, token, loading } = appContext;

  useEffect(() => {
    const timer = setTimeout(() => {
      getRidesDistance(token);
    }, 1000);

    const interval = setInterval(() => {
      const timer = setTimeout(() => {
        getRidesDistance(token);
        console.log("getRidesDistance every 5min");
      }, 500);
      return () => clearTimeout(timer);
    }, 300 * 1000);
    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [token]);

  if (loading) {
    return <Loading />;
  }

  if (!ridesDistance || ridesDistance.length === 0) {
    return null;
  }

  ridesDistance.data.map((item) => {
    values.push(item.value);
  });

  values.sort((a, b) => a - b);
  // let lowMiddle = Math.floor((values.length - 1) / 2);
  // let highMiddle = Math.ceil((values.length - 1) / 2);
  // let median = (values[lowMiddle] + values[highMiddle]) / 2;

  const sum = values.reduce((sum, val) => (sum += val));
  const len = values.length; //Amount of months

  const monthlyAverage = sum / len;
  const weeklyAverage = monthlyAverage / 4;

  return <Module value={Math.ceil(weeklyAverage)} />;
};

export default RidesDistance;
