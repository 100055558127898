import {
    AUTHENTICATING,
    GET_RIDES_TOTAL,
    GET_RIDES_DISTANCE,
    GET_RIDES_TODAY,
    GET_RIDES_BEST_DAY_OF_WEEK,
    GET_RIDES_TRAVELED,
    GET_TOTAL_MINUTES_OF_RIDES,
    GET_ACTIVATED_USERS,
    SET_LOADING,
  } from '../types';
  
  export default (state, action) => {
    switch(action.type) {
        case AUTHENTICATING: 
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_RIDES_TOTAL:
            return {
                ...state,
                ridesTotal: action.payload,
                loading: false,
            }
        case GET_RIDES_DISTANCE:
            return {
                ...state,
                ridesDistance: action.payload,
                loading: false,
            }
        case GET_RIDES_TODAY:
            return {
                ...state,
                ridesToday: action.payload,
                loading: false,
            }

        case GET_RIDES_BEST_DAY_OF_WEEK:
            return {
                ...state,
                ridesBestDayOfWeek: action.payload,
                loading: false,
            }

        case GET_RIDES_TRAVELED:
            return {
                ...state,
                ridesTraveled: action.payload,
                loading: false,
            }

        case GET_TOTAL_MINUTES_OF_RIDES:
            return {
                ...state,
                totalMinutesOfRides: action.payload,
                loading: false,
            }

        case GET_ACTIVATED_USERS:
            return {
                ...state,
                activatedUsers: action.payload,
                loading: false,
            }
            
        default:
            return state;
    }
  }
  