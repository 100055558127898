import React, {Fragment, useEffect, useState} from 'react';
import Brandlogo from '../assets/images/koppla-logo.svg';
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/da';
//https://www.npmjs.com/package/react-moment

const Header = () => {
    const [time, setTime] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(time => time + 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Fragment>
            <header className="header row text-center d-lg-flex align-items-lg-center justify-content-lg-between">
                <figure className="col-12 col-lg-4 text-center text-lg-left header--brand-logo mx-auto mb-3 mb-lg-0">
                    <img src={Brandlogo} alt="Koppla Dashboard" />
                </figure>

                <div className="col-12 col-lg-4 text-center header--tagline text-uppercase">
                    Realtidsdata fra cykler med<br />
                    Koppla Connect
                </div>

                <div className="col-12 col-lg-4 text-center text-lg-right header--date mt-3 mt-lg-0">
                    <Moment format="MMMM DD YYYY" locale="da"/> <Moment format="HH:mm:ss" locale="da"/>
                </div>
            </header>
        </Fragment>
    )
}
export default Header;
