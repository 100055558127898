import React, {useEffect, useContext} from 'react';
import AppContext from '../context/app/appContext';
import Loading from './Loading';
import Text from './Text';

const RidesBestDayOfWeek = () => {
    let data = [];
    let bestDayValue = 0;
    let bestDayKey = '';
    const dayMapping = {
        monday: 'Man',
        tuesday: 'Tirs',
        wednesday: 'Ons',
        thursday: 'Tors',
        friday: 'Fre',
        saturday: 'Lør',
        sunday: 'Søn',
    };
    
    const appContext = useContext(AppContext);
    const { getRidesBestDayOfWeek, ridesBestDayOfWeek, token, loading } = appContext;

    useEffect(() => {
        const timer = setTimeout(() => {
            getRidesBestDayOfWeek(token);
        }, 1000);
        
        const interval = setInterval(() => {
            getRidesBestDayOfWeek(token);
            console.log('getRidesBestDayOfWeek every 5min'); 
        }, 300 * 1000);
        return () => {
            clearTimeout(timer);
            clearInterval(interval);
        }
        
    }, [token]);

    if(loading) {
        return (
            <Loading/>
        )
    }

    if(!ridesBestDayOfWeek || ridesBestDayOfWeek.length === 0) {
        return null;
    }
    

    ridesBestDayOfWeek.data.map((item) => {
        data.push(item);
    })

    for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (bestDayValue < item.value) {
          bestDayKey = item.key;
          bestDayValue = item.value;
        }
    }

    const temp = bestDayKey.replace('day.','');
    const day = dayMapping[temp];

    return (
        <Text value={day} />
    )
}

export default RidesBestDayOfWeek;
