import React, {useEffect, useContext} from 'react';
import AppContext from '../context/app/appContext';
import Loading from './Loading';
import Module from './Module';

const RidesToday = () => {
    const appContext = useContext(AppContext);
    const { getRidesToday, ridesToday, token, loading } = appContext;

    useEffect(() => {
        const timer = setTimeout(() => {
            getRidesToday(token);
        }, 1000);
        
        const interval = setInterval(() => {
            const timer = setTimeout(() => {
                getRidesToday(token);
                console.log('getRidesToday every 5min'); 
            }, 500);
            return () => clearTimeout(timer);
        }, 300 * 1000);
        return () => {
            clearTimeout(timer);
            clearInterval(interval);
        }
    }, [token])

    if(loading) {
        return (
            <Loading/>
        )
    }

    if(!ridesToday || ridesToday.length === 0) {
        return null;
    }

    return (
        <Module value={ridesToday.value} />
    )
}

export default RidesToday;
