import React, {useEffect, useContext} from 'react';
import AppContext from '../context/app/appContext';
import Loading from './Loading';
import Module from './Module';

const RidesTotal = () => {
    let total = [];
    const appContext = useContext(AppContext);
    const { getRidesTotal, ridesTotal, token, loading } = appContext;

    useEffect(() => {
        const timer = setTimeout(() => {
            getRidesTotal(token);
        }, 1000);
        
        const interval = setInterval(() => {
            const timer = setTimeout(() => {
                getRidesTotal(token);
                console.log('getRidesTotal every 5min'); 
            }, 500);
            return () => clearTimeout(timer);
        }, 300 * 1000);
        return () => {
            clearTimeout(timer);
            clearInterval(interval);
        }
    }, [token])

    if(loading) {
        return (
            <Loading/>
        )
    }

    if(!ridesTotal || ridesTotal.length === 0) {
        return null;
    }

    ridesTotal.data.map((item) => {
        total.push(item.value);
    })

    return (
       <Module value={total.reduce((a, b) => a + b)} />
    )
}

export default RidesTotal;
